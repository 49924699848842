// money panel
.realm {
  &--money-panel {
    font-family: $font-family-roboto;
    font-weight: 300;
    padding: 4rem;
    text-align: center;
    background-color: #262324;
    color: #fff;
    @media screen and (min-width: $bp2) {
      padding: 7rem;
    }
  }
  &--money-panel-amount {
    font-size: 2.5rem;
    @media screen and (min-width: $bp2) {
      font-size: 4.5rem;
    }
  }
  &--money-panel-headline {
    margin-top: 2rem;
    font-size: 3rem;
    line-height: 1.2;
    font-family: $font-family-cormorant;
  }
  &--money-panel-copy {
    padding: 2rem;
    font-size: .875rem;
    line-height: 1.625;
    @media screen and (min-width: $bp2) {
      padding: 2rem 7rem;
    }
  }
}
