.realm-footer {
  padding: 4.5rem;
  font-family: $font-family-roboto;
  font-size: 0.9375rem;
  font-weight: 300;
  letter-spacing: .05em;
  text-align: center;
  text-transform: uppercase;
  background-color: #262324;
  border-top: 1px solid #666;
  &--links {
    width: 90%;
    margin: 0 auto;
    a {
      display: block;
      margin-bottom: 1rem;
      line-height: 1.2;
      color: #ddd;
      text-decoration: none;
      @media screen and (min-width: 600px) {
        display: inline-block;
        margin: 0 1rem;
        line-height: 2;
      }
      &:hover {
        color: #d2763d;
        text-decoration: none;
      }
      &:link,
      &:visited {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  &--social-links {
    padding: 2.5rem 0;
  }
  &--social {
    margin: .75rem;
    font-size: 1.5rem;
    color: #fff;
    &:hover {
      color: #d2763d;
      text-decoration: none;
    }
  }
  &--copyright {
    margin-top: 18px;
    font-size: 0.6875rem;
    color: #ddd;
  }
  &--version {
    font-size: 0.6875rem;
    float: right;
    color: #7c7a7a;
  }
}
