.realm {
  &--header {
    height: 80px;
   background: var(--header-color1, black) !important;
  }
  &--header-logo-box {
    display: flex;
    align-items: center;
    img {
      width: 110px;
    }
  }
  &--header-env-name {
    font-size: 20px;
    margin-left: 20px;
  }
  &--header-menu-button {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    margin-right: 12px;
    margin-top: 2px;
    &:hover {
      color: #479acc;
      text-decoration: none;
    }
  }
  &--header-menu-item {
    font-size: 14px;
    font-weight: 400;
    margin-left: 12px;
    &:hover {
      color: #479acc;
      text-decoration: none;
    }
  }
  &--anchor-nav {
    @media screen and (max-width: 830px) {
      display: none;
    }
  }
  &--header-social {
    @media screen and (max-width: 830px) {
      display: none!important;
    }
  }
}

// HEADER OVERRIDE
div.MuiToolbar-root-57.MuiToolbar-regular-59.MuiToolbar-gutters-58 {
  min-height: 4rem;
  @media (min-width: 600px) {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}
