.tools {
  &--email-form {
    width: 80%;
    border: 1px solid #888;
    border-radius: 8px;
    padding: 16px;
    font-size: 16px;
    margin: 24px auto;

    a {
      color: #fff!important;
    }
  }
  &--email-cell {
    font-size: 15px !important;
    color: #fff !important;
    a {
      color: #fff!important;
    }
  }
  &--email-cell-100 {
    width: 100px;
  }
  &--email-cell-350 {
    width: 350px;
  }
  &--email-form-line {
    margin-bottom: 12px;
    font-size: 14px;
  }
  &--email-form-label {
    display: inline-block;
    width: 70px;
    color: #aaa;
  }
  &--email-form-body {
    display: inline-block;
    width: 200px;
    vertical-align: text-top;
  }
}
