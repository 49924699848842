$bp1: 37.5rem!default;
$bp2: 48rem!default;
$bp3: 80rem!default;

// overall
.realm {
  &--root {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &--top-container {
    width: 100%;
  }
  &--wrapper {
    width: 100%;
    height: 100%;
    background: #2b2d3c!important;
    color:white;
  }
  &--loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &--container {
    max-width: $bp3;
    margin: 0 auto;
  }
  &--top-copy-area {
    color: #fff;
    padding: 15rem 2rem 0;
    max-width: 29.75rem;
    @media screen and (min-width: $bp2) {
      margin-left: 55%;
      padding-top: 33vh;
    }
  }
  &--photo-panel {
    margin-bottom: 0;
    font-size: 1.125rem;
    font-family: $font-family-roboto;
    background: #fff;
    @media screen and (min-width: $bp2) {
      display: flex;
      height: 41.25rem;
    }
  }
  &--marquee-text {
    h2 {
      margin: 1rem 0;
      font-family: $font-family-cormorant;
      font-size: 4.375rem;
      font-weight: 300;
      line-height: 1;
      color: #fff;
      @media screen and (min-width: $bp2) {
        margin: 4rem 0;
      }
    }
    h3 {
      font-size: 1.125rem;
      font-family: $font-family-roboto;
      font-weight: 300;
      letter-spacing: .08em;
    }
  }
  &--actions {
    margin-left: -.5rem;
  }
}

.panel-image {
  width: 100%;
  height: 26.25rem;
  color: #fff;
  @media screen and (min-width:$bp2) {
    width: 50%;
    height: 41.25rem;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    transition: background-size 500ms ease;
    &:hover {
      height: 41.375rem;
      background-repeat: no-repeat !important;
      background-size: auto 110% !important;
      transition: background-size 500ms ease;
    }
  }
}

// block section
.text-panel-left,
.text-panel-right {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  &.bottom {
    @media screen and (min-width: $bp2) {
      align-items: flex-end;
    }
    .text-panel-left--content {
      padding-bottom: 4rem;
    }
  }
}

.text-panel-left {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  &--realm {
    margin-bottom: .25rem;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }
  &--big-word {
    font-family: $font-family-cormorant;
    font-size: 3.625rem;
  }
}

.text-panel-right {
  width: 100%;
  height: 100%;
  padding: 5rem;
  font-size: .75rem;
  line-height: 2;
  letter-spacing: .08em;
  color: #1f1b1a;
  @media screen and (min-width: $bp2) {
    width: 50%;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      margin-left: 1em;
    }
  }
}

.collage-panel {
  &--base {
    width: 100%;
    color: #fff;
    @media screen and (min-width: $bp1) {
      height: 0;
      background-repeat: no-repeat!important;
      background-size: auto 100%!important;
      box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
      transition: background-size 500ms ease;
        &:hover {
          width: 100%;
          background-repeat: no-repeat!important;
          background-size: auto 110%!important;
          transition: background-size 500ms ease;
        }
    }
  }
  &--small {
    padding-bottom: 58%;
  }
  &--square {
    padding-bottom: 90%;
  }
  &--square-height {
    height: 40rem;
  }
  &--vertical {
    height: 50rem;
  }
}

// collage section
.realm-collage {
  width: 100%;
  background: #fff;
  &--item-blurb {
    > div {
      padding: 2rem;
      font-size: 1rem;
      font-family: $font-family-cormorant;
      font-weight: 300;
      letter-spacing: .02em;
      line-height: 1.4;
      color: #777;
    }
  }
  @media screen and (min-width:$bp1) {
    &--row {
      display: flex;
    }
    &--item {
      flex: 1;
    }
    &--item-large {
      flex: 7;
    }
    &--item-split {
      flex: 5;
    }
    &--item-image {
      height: 50%;
    }
    &--item-blurb {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      height: 54%;
      &.short {
        height: 10rem;
      }
      > div {
        width: 60%;
        padding: 0;
      }
    }
  }
}
