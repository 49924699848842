.realm {
  &--post-title {
    font-family: $font-family-cormorant;
    font-size: 36px;
    margin-bottom: 44px;
    margin-top: -10px;
  }
  &--full-post {
    color: #333;
    img {
      width: 100%;
      height: auto;
    }
  }
}
