// quote panel
.realm {
  &--quote-panel {
    padding: 4rem 2rem 4rem 0rem;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    text-indent: -1rem;
    background-color: #d2763d;
    color: #fff;
    font-family: $font-family-cormorant;
    @media screen and (min-width: $bp1) {
      min-height: 31.25rem;
      padding: 8rem 12rem;
      font-size: 1.5rem;
    }
  }
  &--quote-panel-item {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    text-align: left;
    @media screen and (min-width: $bp1) {
      margin-bottom: 8rem;
    }
  }
  &--quote-panel-item-image {
    flex: 1 20%;
    img {
      max-width: 18.75rem;
    }
  }
  &--quote-panel-item-content {
    flex: 5;
    padding-left: 4rem;
    .fa-quote-right {
      margin-left: 1.2em;
    }
  }
  &--quote-panel-quoteSize {
    font-size: 1.125rem;
    margin-left: .5rem;
    margin-right: .5rem;
    color: #f9b58b;
  }
}
