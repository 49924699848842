.realm {
  &--page-with-title {
    padding-top: 70px;
  }
  &--page-for-copy {
    margin: 32px;
    @media screen and (min-width: 1280px) {
      width: 1280px;
      margin: 32px auto;
    }
  }
  &--bold {
    font-weight: bold;
  }
}
