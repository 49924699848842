// contact panel
.realm {
  &--contact-panel {
    padding: 4rem;
    font-family: $font-family-roboto;
    text-align: center;
    background :#fff;
    color: #444;
  }
  &--contact-title {
    font-family: $font-family-cormorant;
    font-size: 2.75rem;
    margin-top: .75rem;
    margin-bottom: 1.25rem;
    line-height: 1;
    color: #000;
  }
  &--contact-form {
    font-size: .75rem;
    text-align: left;
  }
  &--contact-text {
    a {
      text-decoration: underline;
    }
  }
}
