.not-found-page {
  h1 {
    font-size: 2em;
    margin-bottom: 0.25em;
  }
}

.page404 {
  text-align: center;
  color: #444;
  text-transform: uppercase;
  font-size: 14px;
  padding: 200px;
}

.message404 {
  font-size: 46px;
  margin-top: 10px;
  margin-bottom: 56px;
  font-family: $font-family-cormorant;
  font-weight: 300;
  text-transform: none;
}

.button404 {
  background-color: rgb(165, 122, 65);
  padding: 12px 60px;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  &:hover {
    background-color: rgb(128, 80, 17);
    text-decoration: none;
  }
}
