.realm{
  &--about {
    text-align: center;
    @media screen and (min-width: 768px) {
      padding: 10rem 0 0;
    }
    @media screen and (min-width: 1180px) {
      width: 1180px;
      margin: 0 auto;
    }
    h1, h2, h3 {
      font-family: $font-family-cormorant;
    }
    h1, h2 {
      font-weight: 400;
      font-size: 48px;
    }
    h1 {
      text-transform: uppercase;
    }
    p {
      font-family: $font-family-roboto;
      text-align: center;
    }
  }
  &--about-label {
    display: block;
    font-family: $font-family-roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  &--about-leadGraph {
    padding: 0 2rem;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: .08em;
    @media screen and (min-width: 960px) {
      padding: 0 12rem;
    }
  }
  &--about-list {
    padding: 0 2rem;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 0 4rem;
    }
  }
  &--about-list-item {
    flex: 1;
    padding: 0 2rem;
    h3 {
      font-size: 24px;
      font-style: italic;
      font-weight: 300;
      letter-spacing: .08em;
      color: #a36535;
      margin-bottom: .5rem;
      @media screen and (min-width: 768px) {
        margin-bottom: 1.25rem;
      }
    }
    p {
      font-size: 13px;
      letter-spacing: .1em;
      line-height: 2;
      margin-top: .5rem;
    }
  }
  &--about-partners {
    @media screen and (min-width: 768px) {
      padding-bottom: 10rem;
      h2 {
        padding-bottom: 4rem;
      }
    }
  }
  &--about-partner {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 0 2rem 2rem;
    @media screen and (min-width: 768px) {
      padding: 0 8rem 4rem;
    }
  }
  &--about-partner-image {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    @media screen and (min-width: 960px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      margin: auto;
    }
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
  &--about-partner-content {
    @media screen and (min-width: 960px) {
      flex: 2;
      padding-left: 4rem;
    }
    h3 {
      font-size: 36px;
      font-weight: 300;
      color: #a36535;
      @media screen and (min-width: 768px) {
        text-align: left;
      }
    }
    p {
      font-size: 13px;
      letter-spacing: .1em;
      line-height: 2;
      text-align: left;
    }
  }
}
