.realm {
  &--page {
    width: 100%;
    padding: 2rem 2.5rem;
    font-size: 16px;
    background-color: #474a6a;
    border: none;
    color: #fff;
    a {
      color: #fff!important;
    }
  }
  &--page-title {
    font-family: $font-family-cormorant;
    margin-bottom: 24px;
    font-size: 24px;
    text-transform: uppercase;
    color: #fff;
  }
  &--page-title-bar {
    position: fixed;
    top: 4rem;
    z-index: 800;
    width: 100%;
    height: 4.25rem;
    margin: 0;
    padding: 2rem 2.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    background-color: #474a6a;
    border: none;
    color: #fff;
    a {
      color: #fff!important;
    }
  }

  &--add-plus {
    margin-right: 5rem;
    float:right;
    font-size: 2rem;
    margin-top: -0.6875rem;
    &:hover {
      color: #bbe;
      transition: all 500ms ease;
    }
  }
}
