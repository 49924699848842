.realm {
  &--not-found-page {
    h1 {
      font-size: 2em;
      margin-bottom: 0.25em;
    }
  }
  &--supported-browser-list {
    padding: 20px;
    font-size: 32px;
    line-height: 1;
    p {
      margin: 10px;
    }
  }

  &--page404 {
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 200px;
  }

  &--message404 {
    font-size: 46px;
    margin-top: 10px;
    margin-bottom: 56px;
    font-family: $font-family-cormorant;
    text-transform: none;
  }

  &--button404 {
    background-color: rgb(165, 122, 65);
    padding: 12px 60px;
    font-size: 18px;
    text-transform: uppercase;
    color:white;
    text-decoration: none;
    &:hover {
      color: black;
      text-decoration: none;
    }
  }
}
