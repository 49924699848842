.tagStyle {
  font-size: 12px;
  background: #bbe;
  border-radius:11px;
  transition: all 0.5s ease;
  width: fit-content;
  display: inline-block;
  margin-right: 8px;
}

.tagStyle:hover {
  background: white;
  transition: all 0.5s ease;
}

.tagLabel {
  padding: 5px;
  padding-bottom:4px;
  padding-left:10px;
  color: #333;
  float:left;
}

.tagAction {
  float:right;
  border-left: 1px solid #aba8a8;
  font-size: 16px;
  padding: 2px 4px;
  margin-left: 6px;
}