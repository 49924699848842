.realm {
  &--localizer {
    width: 300px;
    background-color: #585858;
    user-select: none;
  }
  &--localizer-container {
    padding: 8px 12px;
  }
  &--localizer-lang-area {
    background: #ebebeb;
    padding: 10px;
    > div {
      font-size: 11px;
    }
  }
  &--localizer-lang-title {
    padding-bottom: 5px;
    margin: 0 5px 10px;
    font-weight: 500;
    color: #363636;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    text-transform: uppercase;
  }
  &--localizer-lang-text {
    display: block;
    margin-bottom: 6px;
    padding: 6px;
    &:hover {
      color: #555!important;
    }
  }
  &--localizer-seperate-line {
    background: #ccc;
    height: 1px;
  }
  &--selected-lang {
    background: #53573d;
    color: #fff!important;
    border-radius: 3px;
    &:hover {
      color: #fff!important;
    }
  }
  &--selected-measure {
    background: #fff!important;
    span {
      color: #585858!important
    }
  }
  &--localizer-pop-btn {
    width: 50%;
    min-height: 26px!important;
    padding: 3px 10px!important;
    border: 1px solid #fff!important;
    border-radius: 0 3px 3px 0;
    span {
      font-size: 11px;
      color: #fff;
    }
  }
  &--localizer-pop-label {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    .mr {
      margin-right: 20px;
    }
  }
  &--localizer-currency-selector {
    width: 150px;
    height: 30px;
    border-radius: 3px;
    float: right;
    font-size: 12px;
  }
  &--localizer-pop-row {
    padding: 8px 0!important;
    line-height: 26px!important;
  }
}
