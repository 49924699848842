.realmMaintenance {
  padding: 6rem 2rem;
  text-align: center;
  @media screen and (min-width: 640px) {
    padding: 10rem 2rem 16rem;
  }
  h1, h3 {
    font-family: $font-family-cormorant;
    font-weight: 300;
  }
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 1;
    @media screen and (min-width: 640px) {
      font-size: 4rem;
    }
  }
  p {
    width: 90%;
    margin: 0 auto;
    line-height: 1.8;
    font-family: $font-family-roboto;
    font-size: 1rem;
    text-align: center;
    @media screen and (min-width: 40rem) {
      width: 35rem;
    }
  }
  &--date {
    font-size: 36px;
    font-weight: 300;
    color: #a36535;
  }
}
